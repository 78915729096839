// Override whatever came in from "styles-new" (defined in project.json)
// "styles-new" should contain initial setup for bootstrapping and may
// or may not be shared with lpms , but not sure
@import '../../../libs/shared/styles/scss/safari-variables.scss';

/*=============================================
=            `NAVBAR                          =
=============================================*/

/*
  NOTE: There are a number of images here that refer to secure-share folder but they are the same
  as images we already have in shared/styles. We need to decide whether these will indeed be the shared
  look (404, 403, paper background, etc) and if so just import them from styles/shared/img
  (NOTE: Make sure we don't import styles/shared/scss as that's still a very large CSS file)
*/
hr {
  border-top: 1px dotted var(--brown-lite);
  margin: 0 0 10px;
}

sl-bootstrap-navbar,
#sidebar .sidebar-header {
  .s_brand-expanded {
    content: var(--navbar-brand-logo);
  }
}
#sidebar.active .sidebar-header {
  .s_brand-collapsed {
    clip-path: inset(0 112px 0 0);
    content: var(--navbar-brand-logo);
    margin-left: 3px;
  }
}

.mainClass {
}

body {
  background-color: $paper;
  color: var(--brown);
}

.s_act {
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
  margin: 0 0 15px;
  padding: 14px 0;
  border-top: 3px double var(--brown);
  border-bottom: 1px solid var(--brown);
  background: url(./assets/img/s_bg-paper.jpg);
}

.s_act.s_no-stick {
  position: relative;
}

/*=============================================
=            `COMMON PAGES                   =
=============================================*/

.common-error-page {
  div.container,
  div.container-fluid {
    animation: fadein 5s;
  }

  .btn {
    min-width: 154px;
    text-transform: uppercase;
  }

  .s_header1 {
    font-size: 2rem;
    font-weight: normal;
  }
  .s_header2 {
    font-size: 1.5rem;
    font-weight: 300;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

/*=====  End of `COMMON PAGES ======*/

:root {
  --navbar-brand-logo: url(./assets/img/serveport-knockout.svg);
}
