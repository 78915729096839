// @font-face {
//   /* Font Awesome Used for Froala Editor */
//   font-family: 'Open Sans';
//   font-weight: 100;
//   src: url('../assets/fonts/fontawesome-webfont.woff2') format('woff2'), url('../assets/fonts/fontawesome-webfont.woff') format('woff');
// }
@font-face {
  /* Light Font (use font-weight: 100) */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/OpenSans-Light.woff2') format('woff2'), url('../assets/fonts/OpenSans-Light.woff') format('woff');
}
@font-face {
  /* Regular Font */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/OpenSans-Regular.woff2') format('woff2'), url('../assets/fonts/OpenSans-Regular.woff') format('woff');
}
@font-face {
  /* Italic Font (use <em> tag or font-style:Italic) */
  font-family: 'Open Sans';
  font-style: Italic;
  font-weight: normal;
  src: url('../assets/fonts/OpenSans-Italic.woff2') format('woff2'), url('../assets/fonts/OpenSans-Italic.woff') format('woff');
}
@font-face {
  /* Semibold Font (use font-weight: 600) */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/OpenSans-Semibold.woff2') format('woff2'), url('../assets/fonts/OpenSans-Semibold.woff') format('woff');
}
@font-face {
  /* Bold Font (use <strong> tag or font-weight: Bold) */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: Bold;
  src: url('../assets/fonts/OpenSans-Bold.woff2') format('woff2'), url('../assets/fonts/OpenSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Dancing Script';
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dancingscript/v15/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BAyo3Sup8.woff2) format('woff2');
}
/* ==================================================
   LATDING FONT
   ================================================== */
@font-face {
  font-family: 'safdings';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/safdings.woff') format('woff');
}
[class^='icon-'],
[class*=' icon-'] {
  font-family: 'safdings';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}
/* ==================================================
   LATDING ICONS
   ================================================== */
.s_safdings {
  display: inline-block;
  font-family: 'safdings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  top: 1px;
}
.fa,
.glyphicon {
  /* Used For Rich Text Editor */
  font: normal normal normal 14px/1 'safdings';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
/* ==================================================
   Rich Text Editor
   ================================================== */
.fa-bold:before {
  content: '\e900';
}
.fa-italic:before {
  content: '\e901';
}
.fa-underline:before {
  content: '\e902';
}
.fa-strikethrough:before {
  content: '\e903';
}
.fa-font:before {
  content: '\e904';
}
.fa-text-height:before {
  content: '\e905';
}
.fa-tint:before {
  content: '\e906';
}
.fa-paragraph:before {
  content: '\e907';
}
.fa-align-left:before {
  content: '\e908';
}
.fa-align-center:before {
  content: '\e909';
}
.fa-align-right:before {
  content: '\e910';
}
.fa-align-justify:before {
  content: '\e911';
}
.fa-list-ol:before {
  content: '\e912';
}
.fa-list-ul:before {
  content: '\e913';
}
.fa-outdent:before {
  content: '\e914';
}
.fa-indent:before {
  content: '\e915';
}
.fa-link:before {
  content: '\e916';
}
.fa-rotate-right:before {
  content: '\e917';
}
.fa-rotate-left:before {
  content: '\e918';
}
.fa-eraser:before {
  content: '\e919';
}
.fa-external-link:before {
  content: '\e920';
}
.fa-magic:before {
  content: '\e921';
}
.fa-edit:before {
  content: '\e922';
}
.fa-unlink:before {
  content: '\e923';
}
