/*=============================================
=            SAFARI VARIABLES            =
=============================================*/

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #9ca6b0;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

/*=============================================
=            BRAND VARIABLES            =
=============================================*/
/*----------  COLORS  ----------*/
$brown: #5c483d;
$info: $brown;
$brown-drk: #1d1816;
$brown-mid: #20130d;
$brown-lite: #aa8e7e;
$tope: #f1ece6;
$topedrk: #ebe2d8;
$tope-lite: #eae5e1;
$clay: #d1c6bd;
$orange: #d77332;
$yellow: #e3a83b;
$yellow-lite: #ead9be;
$orange-lite: lighten($orange, 56%);
$green: #00994d;
$green-lite: lighten($green, 56%);
$blue: #297aa3;
$blue-lite: lighten($blue, 56%);
$red: rgb(220, 70, 70);
$red-lite: rgba(220, 70, 70, 0.05);
$red-brite: #ff5656;
$gray: rgb(148, 148, 158);
$gray-lite: rgba(121, 121, 134, 0.07);
$pink: #ff5ac4;
$purple: #a25ddc;
$warning: $yellow;
$light: $gray-200;
$dark: $brown;
$gold: #c2a87a;
$gold-drk: darken($gold, 10%);
$paper: #f5f5f5;
$bg: #f3f1f2;
$alert: #d55c5c;
/*----------  COLORED VARIABLED  ----------*/

$txthighlight: yellow;
$darken: #ebe4e0;
$lighten: #f7f5f3;
$tan: #ebe4e0;
$label: #805f4d;
$label-dark: #7c6252;
$act-bgbot: #dfdad4;
$act-bg: $tope;
$act-sdw: inset 0px -10px 15px -15px rgba(0, 0, 0, 0.3);

$sky: #2293ec;

/*----------  SHADOWS  ----------*/
$trsp: rgba(35, 57, 76, 0.1);
$sdw: rgba(0, 0, 0, 0.3);
$sdw-in:
  inset 0px 8px 8px -8px rgba(0, 0, 0, 0.2),
  inset 0px -8px 8px -8px rgba(0, 0, 0, 0.2);
$sdw-out:
  0px 8px 8px -8px rgba(0, 0, 0, 0.2),
  0px -8px 8px -8px rgba(0, 0, 0, 0.2);
$sdw-in-top: inset 0px 8px 8px -8px rgba(0, 0, 0, 0.2);
$sdw-top: 0px -8px 8px -8px rgba(0, 0, 0, 0.2);
$sdw-bot: 0 5px 5px -2px rgba(0, 0, 0, 0.1);
$sdw-sticky: 0px 8px 8px -6px rgba(0, 0, 0, 0.2);
$glw: 0 0 10px 0 rgba(0, 0, 0, 0.1);

/*----------  RADIUS  ----------*/

$rds: 2px;

/*----------  FONT  ----------*/

$ico28: 28px 'safdings';

/*----------  MISC  ----------*/

$phzcount: 20%;
/* Determines width/number of phases + 1 label (i.e. 5=20% ) */

/*=============================================
=            MIXINS            =
=============================================*/

@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin doublebot {
  border-bottom: 1px solid lighten($brown, 10%);
  // box-shadow: 0 3px 2px -1px #d9d5ce,inset 0 -3px 2px -1px #d9d5ce;
}
@mixin scroller {
  &::-webkit-scrollbar {
    height: 18px;
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow:
      inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-thumb:vertical:hover,
  &::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: var(--brown);
    transition: ease-in-out;
  }

  &::-webkit-scrollbar:horizontal {
    height: 14px;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin scroller-bot {
  &::-webkit-scrollbar {
    height: 14px;
    width: 18px;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow:
      inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-thumb:vertical:hover {
    background-color: var(--brown);
    transition: ease-in-out;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
/*=====  End of MIXINS  ======*/

/*=============================================
=            BOOTSTRAP OVERRIDES            =
=============================================*/
$body-color: $black;
$border-radius: 2px;
$border-radius-lg: 3px;
$border-radius-sm: 2px;
$font-weight-lighter: 100;
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$line-height-base: 1.25;
$component-active-bg: lighten($blue, 40%);
$font-family-sans-serif:
  'open sans',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI';
$link-hover-decoration: none;
$font-size-base: 0.9rem;
$form-check-input-gutter: 0;
$input-group-addon-bg: $white;
$input-border-color: lighten($label, 25%); // REVISIT - This particular variable won't be themed; No direct usages
$input-box-shadow: none;
$input-btn-focus-width: 0;
$input-btn-focus-color: transparent;
$input-btn-focus-box-shadow: none;
$btn-box-shadow: none;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;

$form-group-margin-bottom: 0.8rem;
$text-muted: $gray-500;
$input-placeholder-color: $gray-500;
$nav-tabs-border-color: $white;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 10%;
$btn-font-weight: 600;
$input-btn-focus-box-shadow: none;
$btn-transition:
  color 0.01s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
$modal-content-border-radius: 0.25rem;
$modal-dialog-margin-y-sm-up: 1rem;
$dropdown-link-active-color: $gray-900;
$label-margin-bottom: 0.1rem;
$component-active-bg: $gray-400;
$input-focus-border-color: $gray-400;
$btn-disabled-opacity: 0.5;
$progress-bar-bg: $clay;
$modal-header-border-color: rgba(0, 0, 0, 0.15);
$tooltip-opacity: 1;
/*----------  CUSTOM FIELDS  ----------*/

$custom-control-indicator-size: 1.5rem;
$custom-control-indicator-checked-bg: $gray-600;
$custom-control-indicator-focus-border-color: $gray-400;
$custom-control-indicator-border-color: darken($gray-400, 10%);
$custom-control-indicator-active-color: $orange;
$custom-control-indicator-active-bg: $white;
$custom-control-indicator-active-border-color: $gray-400;
$custom-file-button-color: $brown;
$custom-file-button-bg: transparent;
$custom-range-thumb-active-bg: $gray-600;
/*=====  End of BOOTSTRAP OVERRIDES  ======*/

/*=============================================
=            `ICONS            =
=============================================*/
$icons:
  dash '\e001' '',
  intake '\e002' '\e012',
  inbox '\e003' '\e013',
  cabinet '\e004' '\e0114',
  chart '\e005' '\e015',
  gear '\e006' '\e016',
  library '\e007' '\e017',
  alert '\e008' '\e018',
  starred '\e009' '',
  recent '\e010' '',
  cal '\e033' '',
  person '\e034' '',
  txt '\e035' '',
  more '\e036' '\e361',
  mail '\e037' '',
  fax '\e038' '',
  email '\e039' '',
  portal '\e040' '\e041',
  check '\e042' '\e043',
  automate '\e050' '\e051',
  lock '\e065' '\e066',
  unlock '\e067' '\e068',
  history '\e069' '\e070',
  note '\e071' '\e072',
  talk '\e310' '\e311',
  link '\e073' '\e074',
  chain '\e307' '',
  bill '\e075' '\e076',
  bell '\e077' '\e078',
  garbage '\e097' '\e098',
  remove '\e099' '\e100',
  close '\e101' '\e102',
  star '\e103' '\e104',
  burger '\e106' '',
  sortdown '\e107' '',
  sortup '\e108' '',
  download '\e109' '\e110',
  twist '\e111' '\e111',
  folder '\e113' '\e114',
  zip '\e115' '\e116',
  file '\e117' '\e118',
  spin '\e119' '\e120',
  shield '\e121' '\e122',
  valid '\e121' '\e123',
  info '\e124' '\e125',
  globe '\e130' '\e129',
  edit '\e131' '\e132',
  viewer '\e155' '\e156',
  send '\e157' '\e158',
  grip '\e208' '\e209',
  ungarbage '\e210' '\e211',
  move '\e255' '\e256',
  mag '\e259' '\e260',
  magclose '\e258' '\e258',
  ocr '\e261' '\e262',
  filter '\e263' '\e264',
  select '\e267' '\e268',
  user '\e312' '\e313',
  workflow '\e314' '\e315',
  lookup '\e316' '\e317',
  transfer '\e318' '\e319',
  forward '\e352' '\e351',
  convertpdf '\e451' '\e452',
  down '\e453' '\e454',
  redact '\e455' '\e456',
  mail '\e457' '\e458',
  stamp '\e459' '\e460',
  sheet '\e461' '\e462',
  expand '\e501' '\e502',
  collapse '\e503' '\e504',
  clip '\e505' '',
  paste '\e506' '\e507',
  invoice '\e508' '\e509',
  null '\e999' '',
  dev '\e040' '\e041',
  nextdate '\e510' '',
  globe '\e512' '',
  addressbook '\e513' '',
  foldup '\e516' '\e517',
  levelup '\e514' '\e515';

/*=====  End of `ICONS  ======*/
:root {
  --brown: #5c483d;
  --brown-mid: #20130d;
  --brown-drk: #1d1816;
  --brown-lite: #aa8e7e;
  --info: #5c483d;
  --label: #805f4d;
  --label-dark: #7c6252;
}
