// Don't let modal dialog be covered by the blockUI component
.modal {
  z-index: 10000000;
}
// Datepicker needs this or the month/year fields look bad.
.custom-select {
  width: auto;
}
.modal-footer {
  flex-wrap: nowrap;
}
.whitespace-normal {
  white-space: normal;
}
div.block-ui-wrapper .loader {
  display: none;
}
block-ui.transparent div.block-ui-wrapper {
  background: rgba(0, 0, 0, 0);
}
typeahead-container .dropdown-header {
  font-size: 1rem;
  margin-left: -10px;
  font-weight: bold;
}
/* =====  Modal Overrides ====== */
/* WE DONT WANT MODALS DIALOG OVERLAYED ON BLOCKUI OVERLAY
   KEEP THIS AT 0 OPACITY
*/
.modal,
.modal-backdrop {
  background: rgba(0, 0, 0, 0);
}
/* =====  End Modal Overrides ====== */
/*
Datatable clips anything that tries to leak outside of the row. Some of our designs however, need to 
show things like dropdowns, etc that will expand past the row borders. These overrides seem to be commonly
suggested solution. Currently applying for all - we'll see if there are any issues with other tables/rows. 
If so we can probably add some extra class and only limit to those, but if no issues we'd probably won't to have
this as default behavior anyway
*/
ngx-datatable:not(.file-table) .datatable-body {
  overflow: visible !important;
}
.datatable-body-cell {
  overflow-y: visible !important;
  overflow-x: visible !important;
}
.ngx-datatable {
  overflow-x: visible !important;
  overflow-y: visible !important;
}
/* We are not using datatable's default sort buttons */
.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  display: none !important;
}
.no_hider > button > ins {
  opacity: 0;
}

.bs-datepicker-container {
  padding: 0px;
}

.bs-datepicker-head {
  background-color: $tan !important;
  button {
    color: var(--label);
  }
}

.text-black {
  color: $black;
}

/*=====  Removes small bootstrap assigned arrow for expand/collapse sections ======*/
.dropdown-toggle::after {
  display: none;
}

bs-datepicker-container {
  // This allows us to see the icon
  top: 32px !important;
}

.is-invalid {
  background-image: none !important;
}
